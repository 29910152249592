<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-07 10:25:27
 * @LastEditTime: 2021-05-12 14:47:47
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="annual-report">
    <LogoTitle msg="年检年报"></LogoTitle>
    <FileList></FileList>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {
  },
  methods: {

  },
}
</script>

<style scoped lang='scss'>
</style>